



.autocomplete-panel {
  position: absolute;
  width: 100%;
  max-width: 20rem;
  border-radius: 0.5rem;
  background-color: rgba(17, 24, 39, 1);
  box-shadow: 0 20px 25px -5px rgba(255, 255, 255, 0.1),
    0 10px 10px -5px rgba(255, 255, 255, 0.04),
    0 1px 3px 0 rgba(255, 255, 255, 0.1), 0 1px 2px 0 rgba(255, 255, 255, 0.06);
}

.autocomplete-loading {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: rgba(14, 165, 233, 1);
}

.autocomplete-loading-icon {
  display: block;
  height: 2rem;
  width: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.autocomplete-source {
  margin-top: 0.5rem;
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.autocomplete-source-stalled {
  filter: grayscale(1);
  opacity: 0.8;
}

.autocomplete-items {
  height: 100%;
  max-height: 24rem;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
}

.autocomplete-item {
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.autocomplete-item > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(0.25rem * calc(1 - 0));
  margin-bottom: calc(0.25rem * 0);
}

.autocomplete-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.autocomplete-item-selected {
  background-color: rgba(31, 41, 55, 1);
}

.account-body {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.account-body > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(0.75rem * 0);
  margin-left: calc(0.75rem * calc(1 - 0));
}

.account-name {
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  overflow: hidden;
}

.account-highlighted {
  border-radius: 0.125rem;
  background-color: rgba(55, 65, 81, 1);
  color: currentColor;
}

.account-avatar,
.box-avatar {
  height: 2.5rem;
  width: 2.5rem;
  flex: none;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
}

.account-avatar img,
.box-avatar img {
  width: 100%;
  height: auto;
  transform: scale(1.2) translateY(-0.2rem);
}

.account-handle {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(107, 114, 128, 1);
  overflow: hidden;
}

